<template>
  <div id="dashboard" class="container mt-3">
    <div class="row">
      <div class="col">

      <h1 class="text-center mt-3 mb-5">Hallo {{$auth.user().agilea.firstName}}!</h1>

      <open-due-payment v-if="$auth.user().agilea.type != 7"></open-due-payment>

      <div class="row">
          <div class="d-md-block  col" >
              <home-my-data></home-my-data>
          </div>
      </div>
      </div>
    </div>
    <div class="row d-md-none ">
        <div class="col">
          <div class="d-grid gap-2 col-10 mx-auto my-5">
            <!--<button class="btn btn-secondary" @click="setModus('myData')" :class="{'d-none': isMyData()}">Meine Daten</button>
            <button class="btn btn-secondary" @click="setModus('myContract')" :class="{'d-none': isMyContract()}">Mein Vertrag</button>
            <button class="btn btn-secondary" @click="setModus('myStudio')" :class="{'d-none': isMyStudio()}">Mein Studio</button>
            <button class="btn btn-secondary" @click="setModus('myKK')" :class="{'d-none': isMyKK()}">KK-Rückerstattung</button>-->
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import HomeMyData from "@/components/HomeMyData";
import OpenDuePayment from "@/components/OpenDuePayment.vue";


export default {
  name: 'MainDashboard',
  components: {
    HomeMyData,
    OpenDuePayment
  },
  computed: {
    contract() {
      return this.$store.state.contract.contract;
    },
  },
  data(){
    return {
      response: null,
      modus: null,
      recaptchaScript: null,
    }
  },
  methods:{
      payment(){
        this.axios.post("myLogin/payOpenDue").then(res => {
          this.recaptchaScript = document.createElement('script');
          this.recaptchaScript.onload = () => {
            console.log(window.LightboxCheckoutHandler);
            window.LightboxCheckoutHandler.startPayment(1, function(){
              alert('An error occurred during the initialization of the payment lightbox.');
            });
          };

          this.recaptchaScript.setAttribute('src', res.data.uri)
          document.head.appendChild(this.recaptchaScript);
          this.sending = false;
        });
      },
      setModus(modus){
          this.modus = modus;
          this.scrollToTop();
      },
      scrollToTop() {
        window.scrollTo(0,0);
      },
      isGroupFitnessAvailable(){
        if(this.contract == false) return false;

        return (this.contract.isGroupFitnessAvailable || this.contract.isAllStudios);
      },
      isGroupFitnessUpgradeAvailable(){
        if(this.contract == false) return false;

        return (this.contract.isAllInOneUpgradeAllowed);
      },
      isMyData(){
        return this.modus == "myData"
      },
      isMyContract(){
        return this.modus == "myContract"
      },
      isMyStudio(){
        return this.modus == "myStudio"
      },
      isMyKK(){
        return this.modus == "myKK"
      },
      sendData(){
        alert('Hello');
          this.axios.post('login', {test: "Schweinebacke"}).then(res => {
              alert(res.data);
          });
      },
    receiveData(){
        this.axios.get('login/info').then(res => {
          alert(res.data);
          this.response = res.data;
        });
    }
  },
  props: {}
}
</script>

